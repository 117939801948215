'use client';

import { AuthStepsEnum, IUserSignInReponse } from '@/types';
import { useAppDispatch, useAppSelector } from '@/redux/redux-hook';

import {
    setLoginPhone,
    setUserId,
    setLoginName,
    setLoginCurrentStep,
    setProgressValue,
    setLoginEmail,
    setShowProfileCard,
    clearCurrentState,
    setIsLoggedIn,
} from '@/redux/reducers/auth.reducer';
import { getUpdatedToken, sendOtp } from '@/api-requests';
import { useRouter, usePathname } from 'next/navigation';
import useTranslationHook from './use-translation-client.hook';
import { authTokenKey, userIdKey } from '@/constants';
import { authStorage } from '@/classes/SylndrStorage';
import parseJwt from '@/libs/parseJWT.lib';
import { useEffect } from 'react';
const useAuth = () => {
    const path = usePathname();
    const {
        name,
        phone,
        email,
        userId,
        currentStep,
        progressValue,
        showProfileCard,
        redirectUrl,
    } = useAppSelector(state => state.auth);
    const { i18n } = useTranslationHook();

    const lang = i18n.language;
    const router = useRouter();
    const dispatch = useAppDispatch();
    const updatePhone = (value: string) => {
        dispatch(setLoginPhone(value));
    };

    const otpConfirmed = (responseObject: IUserSignInReponse) => {
        const token = responseObject?.payload?.data;
        if (token) {
            // localStorage.setItem('userToken', token);
            authStorage.setItem(authTokenKey, token);
            try {
                const decodedToken: any = parseJwt(token);
                if (decodedToken) {
                    if (decodedToken.id) {
                        dispatch(setUserId(decodedToken.id));
                        authStorage.setItem(userIdKey, decodedToken.id);
                    }
                    if (decodedToken.phone)
                        dispatch(setLoginPhone(decodedToken.phone));
                    if (decodedToken.name) {
                        updateCurrentAuthStep(AuthStepsEnum.spinner);
                        dispatch(setLoginName(decodedToken.name));
                    } else {
                        updateCurrentAuthStep(AuthStepsEnum.name);
                        dispatch(setProgressValue(90));
                    }
                    if (decodedToken.email) {
                        dispatch(setLoginEmail(decodedToken.email));
                    }
                }
            } catch (error) {
                // console.error('Error decoding JWT:', error);
            }
        }
    };
    const signInRetailCheckout = (token: string) => {
        if (token) {
            authStorage.setItem(authTokenKey, token);
            try {
                const decodedToken: any = parseJwt(token);
                if (decodedToken) {
                    if (decodedToken.id) {
                        dispatch(setUserId(decodedToken.id));
                        authStorage.setItem(userIdKey, decodedToken.id);
                    }
                    if (decodedToken.phone)
                        dispatch(setLoginPhone(decodedToken.phone));
                    if (decodedToken.name)
                        dispatch(setLoginName(decodedToken.name));
                    if (decodedToken.email)
                        dispatch(setLoginEmail(decodedToken.email));
                }
                return decodedToken.id;
            } catch (error) {
                // console.error('Error decoding JWT:', error);
            }
        }
    };

    const resendOtp = () => {
        sendOtp({ phone, language: lang });
    };

    const goToNextStep = () => {
        if (currentStep === AuthStepsEnum.phone) {
            updateCurrentAuthStep(AuthStepsEnum.otp);
            dispatch(setProgressValue(66));
        }
        if (currentStep === AuthStepsEnum.otp) {
            updateCurrentAuthStep(AuthStepsEnum.name);
            dispatch(setProgressValue(90));
        }
        if (currentStep === AuthStepsEnum.name) {
            updateCurrentAuthStep(AuthStepsEnum.email);
            dispatch(setProgressValue(90));
        }
        if (currentStep === AuthStepsEnum.email) {
            updateCurrentAuthStep(AuthStepsEnum.phone);
            dispatch(setProgressValue(33));
        }
    };
    const checkForTokenAndDecode = () => {
        // const token = localStorage.getItem('userToken');
        const token = authStorage.getItem(authTokenKey);
        if (token) {
            try {
                const decodedToken: any = parseJwt(token);
                if (decodedToken) {
                    if (decodedToken.id) {
                        dispatch(setUserId(decodedToken.id));
                        authStorage.setItem(userIdKey, decodedToken.id);
                    }
                    if (decodedToken.phone)
                        dispatch(setLoginPhone(decodedToken.phone));
                    if (decodedToken.email) {
                        dispatch(setLoginEmail(decodedToken.email));
                    }
                    if (decodedToken.name) {
                        dispatch(setLoginName(decodedToken.name));
                        return decodedToken.name;
                    }
                }
            } catch (error) {
                // console.error('Error decoding JWT:', error);
            }
        }
    };
    const clearTokenAndLogout = () => {
        dispatch(clearCurrentState());
        // localStorage.removeItem('userToken');
        authStorage.deleteItem(authTokenKey);
        authStorage.deleteItem(userIdKey);
    };
    const updateCurrentAuthStep = (value: AuthStepsEnum) => {
        dispatch(setLoginCurrentStep(value));
    };
    const setShowProfileCardWrapper = (value: boolean) => {
        dispatch(setShowProfileCard(value));
    };
    const nameConfirmed = (responseObject: any) => {
        const userName = responseObject?.payload?.data?.name;
        if (userName) {
            dispatch(setLoginName(userName));
            updateCurrentAuthStep(AuthStepsEnum.spinner);
            updateToken();
            router.push(`/${lang}`);
        } else {
            // console.error('name is missing in response:', responseObject);
        }
    };
    const emailConfirmed = (responseObject: any) => {
        const userEmail = responseObject?.payload?.data?.email;
        if (userEmail) {
            dispatch(setLoginEmail(userEmail));
            updateCurrentAuthStep(AuthStepsEnum.spinner);
            updateToken();
            router.push(`/${lang}`);
        } else {
            // console.error('email is missing in response:', responseObject);
        }
    };
    const personalInformationUpdated = (responseObject: any) => {
        const userEmail = responseObject?.payload?.data?.email;
        const userName = responseObject?.payload?.data?.name;
        if (userEmail) {
            dispatch(setLoginEmail(userEmail));
        }
        if (userName) {
            dispatch(setLoginName(userName));
        }
        updateCurrentAuthStep(AuthStepsEnum.spinner);
        updateToken();
        // router.push(`/${lang}`);
    };
    const updateToken = async () => {
        const fetchTokenResponse = await getUpdatedToken();
        const token = fetchTokenResponse?.payload?.data;
        if (token && fetchTokenResponse.statusCode === 200) {
            // localStorage.setItem('userToken', token);
            authStorage.setItem(authTokenKey, token);
            try {
                const decodedToken: any = parseJwt(token);
                if (decodedToken) {
                    if (decodedToken.id) {
                        dispatch(setUserId(decodedToken.id));
                        authStorage.setItem(userIdKey, decodedToken.id);
                    }
                    if (decodedToken.phone)
                        dispatch(setLoginPhone(decodedToken.phone));
                    if (decodedToken.name) {
                        dispatch(setLoginName(decodedToken.name));
                    }
                    if (decodedToken.email) {
                        dispatch(setLoginEmail(decodedToken.email));
                    }
                }
            } catch (error) {
                // console.error('Error decoding JWT:', error);
            }
        }
    };
    const mobileLogout = () => {
        setShowProfileCardWrapper(false);
        clearTokenAndLogout();
        updateCurrentAuthStep(AuthStepsEnum.spinner);
        router.push(`/${i18n.language}`);
    };
    const getUserInitials = () => {
        const splittedName = name.split(' ');
        let outputString = '';
        if (splittedName[0] && splittedName[0][0]) {
            outputString += splittedName[0][0];
        }
        if (splittedName[1] && splittedName[1][0]) {
            outputString += ' ' + splittedName[1][0];
        }
        return outputString;
    };
    const userInitials = getUserInitials();

    const signInOrUpClicked = () => {
        if (!path.includes('/register')) {
            updateCurrentAuthStep(AuthStepsEnum.spinner);
            router.push(`/${i18n.language}/register`);
            updateCurrentAuthStep(AuthStepsEnum.phone);
        }
    };
    const openProfileClicked = () => {
        updateCurrentAuthStep(AuthStepsEnum.spinner);
        router.push(`/${i18n.language}/account/profile`);
    };

    const updateProgressValue = (value: number) => {
        dispatch(setProgressValue(value));
    };
    useEffect(() => {
        if (name.length === 0) {
            dispatch(setIsLoggedIn(false));
        } else {
            dispatch(setIsLoggedIn(true));
        }
    }, [name]);
    const isLoggedIn = !!name;

    return {
        name,
        phone,
        email,
        userId,
        progressValue,
        currentStep,
        showProfileCard,
        userInitials,
        isLoggedIn,
        redirectUrl,
        updatePhone,
        otpConfirmed,
        resendOtp,
        goToNextStep,
        checkForTokenAndDecode,
        clearTokenAndLogout,
        setShowProfileCardWrapper,
        updateCurrentAuthStep,
        nameConfirmed,
        emailConfirmed,
        mobileLogout,
        signInOrUpClicked,
        openProfileClicked,
        personalInformationUpdated,
        signInRetailCheckout,
        updateProgressValue,
        updateToken,
    };
};
export default useAuth;
